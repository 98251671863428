@charset 'UTF-8';
@import "./setting/variables";

.GPC0065 {
  // Common
  position: relative;
  background-image: linear-gradient(-180deg, #fff 5%, #f2f2f2 50%, #fff 95%);
  &.align-left {
    .item {
      .model-name,
      .body-copy {
        text-align: left;
      }
    }
  }
  &.align-right {
    .item {
      .model-name,
      .body-copy {
        text-align: right;
      }
    }
  }
  &.align-center {
    .item {
      .model-name,
      .body-copy {
        text-align: center;
      }
    }
  }
  &.component.component-outer-box {
    max-width: 1600px;
    padding: 5px 80px 5px;
    .component-inner-box {
      margin: 0 auto;
    }
    @include screen(custom, max, $max-sm) {
      padding: 5px 0 15px;
    }
  }
  .slick-track {
    margin: 0 auto;
  }
  .title,
  .title h1,
  .title h2,
  .title h3,
  .title h4,
  .title h5,
  .title h6 {
    max-width: 800px;
    margin: 0 auto 38px auto;
    font-size: 38px;
    line-height: 1.2;
    @include font-family($font-semibold);
    color: $color-black;
    @include screen(custom, max, $max-md) {
      font-size: 36px;
    }
    @include screen(custom, max, $max-sm) {
      font-size: 28px;
      margin: 0 auto 24px auto;
      padding: 0 24px;
    }
    @include screen(custom, max, 374) {
      font-size: 26px;
    }
  }

  // Common - hometype only (PJTQUICKWIN 20220114 add)
  &.hometype {
    position: relative;
    background-image: none;
    background-color: $bg-desertstorm;
    &.component.component-outer-box {
      max-width: 1600px;
      padding: 100px 60px 100px;
      .component-inner-box {
        margin: 0 auto;
      }
      @include screen(custom, max, $max-sm) {
        padding: 35px 24px;
        max-width: none;
      }
    }
    .slick-track {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      padding: 10px 0;
      @include screen(custom, max, $max-sm) {
        padding: 0;
      }
    }
    .btn-more {
      position: relative;
      display: none;
      color: $bg-nightrider;
      background-color: transparent;
      @include font-family($font-bold);
      font-size: 14px;
      @include pxtoemLineHeight(14, 14);
      text-align: center;
      border: none;
      padding: 0 25px;
      margin: 14px auto 5px;
      min-height: 18px;
      text-transform:uppercase;
      &:after {
        content: "";
        width: 10px;
        height: 8px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
        background-repeat: no-repeat;
        background-size: 10px auto;
        margin-left: 7px;
        display: inline-block;
        vertical-align: middle;
      }
      @include screen(custom, max, $max-sm) {
        &.show {
          display: inline-block;
        }
      }
    }
  }

  // Category Selector
  .product-type {
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    max-width: 1284px;
    margin: 0 auto;
    @include screen(custom, max, $max-sm) {
      margin: 0;
    }
    .items {
      @extend %clearfix;
      width: 100%;
      opacity: 0;
      &.slick-initialized {
        opacity: 1;
      }
      a {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        border: 1px solid $line-gray;
        padding: 35px 20px 30px;
        border-radius: 10px;
        background-color: $bg-white;
        @include screen(custom, max, $max-sm) {
          max-width: 100%;
          padding: 35px 10px 30px;
        }
      }
    }
    .item {
      float: left;
      [dir="rtl"] & {
        float: right;
      }
      margin: 0 15px;
      outline: none;
      @include screen(custom, max, $max-sm) {
        margin: 0 15px;
      }
      @include screen(custom, max, 320) {
        margin: 0 8px;
      }
      @include screen(custom, min, $min-lg) {
        &:nth-child(4n-3) {
          &:before {
            display: none;
          }
        }
      }
      @include screen(md) {
        &:nth-child(3n-2) {
          &:before {
            display: none;
          }
        }
      }
      @include screen(custom, max, $max-sm) {
        &:nth-child(2n-1) {
          &:before {
            display: none;
          }
        }
      }
      .visual {
        text-align: center;
      }
      .visual,
      .model-name {
        width: 100%;
        margin: 0 auto;
      }
      .body-copy {
        font-size: 16px;
        line-height: 24px;
        color: $color-dimgray;
        margin: 0;
        line-height: 1.4em;
        min-height: 4.2em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include screen(custom, max, $max-sm) {
          margin-bottom: 16px;
        }
      }
      .visual {
        // background:rgba(165,0,52,0.2);
        // img {width:100%;height:auto;}
        width: auto;
        a {
          display: block;
        }
        img {
          max-width: 180px;
        }
        margin-bottom: 35px;
        @include screen(custom, max, $max-sm) {
          padding: 0 15px;
          margin-bottom: 30px;
          box-sizing: border-box;
          img {
            width: auto !important;
          }
        }
      }
      .model-name {
        height: (26px * 2);
        font-size: 20px;
        line-height: 26px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        // 20200608 START ������ - RTL ���� ��ȣ�� ������ ��� ������ ���� ����
        [dir="rtl"] & {
          display: block;
        }
        // 20200608 END
        @include screen(custom, max, $max-sm) {
          margin-top: 0;
        }
        .text {
          color: $color-nightrider;
          @include font-family($font-semibold);
          font-size: 20px;
          line-height: 26px;
          span {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 3px;
            text-indent: -9999px;
            font-size: 0;
            background: url($link-right-dark) //LGCOMPSPEED-11
              no-repeat 0 1px;
            [dir="rtl"] & {
              margin-left: 0;
              margin-right: 3px;
              transform: rotate(180deg);
            }
          }
        }
        &.top {
          margin-bottom: 19px;
          @include screen(custom, max, $max-sm) {
            margin-bottom: 14px;
          }
        }
        &.bottom {
          margin-bottom: 8px;
        }
      }
    }

    // slick carousel stylesheet
    .slick-slider {
      button {
        appearance: none;
        border: none;
        background-color: transparent;
      }
      .slick-list {
        width: auto;
        overflow: hidden;
        @include screen(custom, max, 1444) {
          padding: 0;
        }
        @include screen(custom, max, $max-sm) {
          padding: 0 45px;
        }
        @include screen(custom, max, 320) {
          padding: 0 20px;
        }
      }
      .slick-arrow {
        padding: 0;
        line-height: 0;
        color: $color-gray;
        overflow: hidden;
        width: 22px;
        height: 40px;
        position: absolute;
        z-index: 99;
        top: 50%;
        transform: translate(0, -50%);
        text-indent: 10em;
        @include screen(custom, max, $max-sm) {
          width: 12px;
          height: 22px;
        }
        [dir="rtl"] & {
          transform: translate(0, -50%) rotate(180deg);
        }
        &:not([disabled]):hover,
        &:not([disabled]):focus {
          //color: $color-nightrider;
          color: $color-black;
        }
        &.slick-prev {
          background: url("/lg5-common-gp/images/common/icons/carousel-left-over.svg")
            0 0;
          background-size: 22px 40px;
          @include screen(custom, max, $max-sm) {
            left: 38px;
            background-size: 12px 22px;
            [dir="rtl"] & {
              left: inherit;
              right: 38px;
            }
          }
        }
        &.slick-next {
          background: url("/lg5-common-gp/images/common/icons/carousel-right-over.svg")
            0 0;
          background-size: 22px 40px;
          @include screen(custom, max, $max-sm) {
            right: 38px;
            background-size: 12px 22px;
            [dir="rtl"] & {
              left: 38px;
              right: inherit;
            }
          }
        }
        &.slick-disabled {
          opacity: 0.3;
        }
        svg {
          display: block;
        }
      }
      .slick-dots {
        text-align: center;
        margin-top: 18px;
        margin-bottom: 0;
        height: 10px;
        li {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 10px;
          height: 10px;
          margin: 0 4px;
          line-height: normal;
          border-radius: 50%;
          border: 1px solid $color-gray;
          a,
          button {
            background: $color-white;
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            border-radius: 50%;
            overflow: hidden;
            color: transparent;
            cursor: pointer;
            &:focus {
              outline: 1px dotted;
              outline: 5px auto -webkit-focus-ring-color;
            }
          }
          &.slick-active {
            border: 1px solid $color-carmine;
            a,
            button {
              background: $color-carmine;
            }
          }
          &:only-child {
            display: none;
          }
        }
      }
    }
  }

  // Category Selector - hometype (PJTQUICKWIN 20220114 add)
  .product-home-type {
    width: 100%;
    max-height: inherit;
    overflow: hidden;
    max-width: 1308px;
    margin: 0 auto;
    @include screen(custom, max, $max-sm) {
      margin: 0;
      max-width: none;
      max-height: none;
    }
    .items {
      @extend %clearfix;
      width: 100%;
      opacity: 0;
      &.slick-initialized {
        opacity: 1;
      }
      @include screen(custom, max, $max-sm) {
        opacity: 1;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 10px);
        margin: 0 -5px;
      }
      a {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 40px 31.5px 40px;
        border-radius: 10px;
        border: none;
        background-color: $bg-white;
        @include screen(custom, max, $max-sm) {
          padding: 20px 4.5px;
        }
      }
    }
    .item {
      float: left;
      display: flex;
      [dir="rtl"] & {
        float: right;
      }
      margin: 0 12px;
      outline: none;
      @include screen(custom, max, $max-sm) {
        display: none;
        max-width: 50%;
        min-width: 50%;
        margin: 0;
        padding: 5px;
        float: none;
        &.show {
          display: block;
        }
      }
      @include screen(custom, min, $min-lg) {
        &:nth-child(4n-3) {
          &:before {
            display: none;
          }
        }
      }
      @include screen(md) {
        &:nth-child(3n-2) {
          &:before {
            display: none;
          }
        }
      }
      @include screen(custom, max, $max-sm) {
        &:nth-child(2n-1) {
          &:before {
            display: none;
          }
        }
      }
      &.item-signature {
        @include screen(custom, max, $max-sm) {
          max-width: 100%;
          min-width: 100%;
          .model-name {
            max-width: inherit;
            text-align: left;
            margin-left: 30px;
          }
          a {
            display: flex;
            align-items: center;
            padding: 20px;
            padding-left: calc(25% - 48px);
            padding-right: calc(25% - 48px);
          }
          .visual {
            margin-bottom: 0;
          }
        }
        @include screen(custom, max, 374) {
          .model-name {
            margin-left: 25px;
          }
        }
      }
      .visual {
        text-align: center;
      }
      .visual,
      .model-name {
        width: 100%;
        margin: 0 auto;
      }
      .body-copy {
        font-size: 16px;
        @include pxtoemLineHeight(16, 22);
        color: $color-gray;
        margin: 0;
        min-height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        -webkit-line-clamp: inherit;
        -webkit-box-orient: inherit;
        @include screen(custom, max, $max-sm) {
          display: none;
          min-height: none;
        }
      }
      .visual {
        width: auto;
        a {
          display: block;
        }
        img {
          max-width: 180px;
        }
        margin-bottom: 30px;
        @include screen(custom, max, $max-sm) {
          padding: 0;
          margin-bottom: 15px;
          box-sizing: border-box;
          img {
            max-width: 90px;
          }
        }
      }
      .model-name {
        height: (26px * 2);
        font-size: 22px;
        @include pxtoemLineHeight(22, 26);
        display: table;
        overflow: hidden;
        word-wrap: break-word;
        margin-bottom: 15px;
        @include screen(custom, max, $max-sm) {
          max-width: 120px;
          height: (18px * 2);
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
          @include pxtoemLineHeight(14, 18);
        }
        .text {
          display: table-cell;
          vertical-align: middle;
          color: $color-nightrider;
          @include font-family($font-bold);
          font-size: 22px;
          @include pxtoemLineHeight(22, 26);
          @include screen(custom, max, $max-sm) {
            font-size: 14px;
            @include pxtoemLineHeight(14, 18);
          }
        }
      }
    }

    // slick carousel stylesheet
    .slick-slider {
      button {
        appearance: none;
        border: none;
        background-color: transparent;
      }
      .slick-list {
        width: auto;
        overflow: hidden;
        @include screen(custom, max, 1444) {
          padding: 0;
        }
        @include screen(custom, max, $max-sm) {
          padding: 0 45px;
        }
        @include screen(custom, max, 320) {
          padding: 0 20px;
        }
      }
      .slick-arrow {
        padding: 0;
        line-height: 0;
        color: $color-gray;
        overflow: hidden;
        width: 22px;
        height: 40px;
        position: absolute;
        z-index: 99;
        top: 50%;
        transform: translate(0, -50%);
        text-indent: 10em;
        margin-top: 2px;
        @include screen(custom, max, $max-sm) {
          width: 12px;
          height: 22px;
        }
        [dir="rtl"] & {
          transform: translate(0, -50%) rotate(180deg);
        }
        &:not([disabled]):hover,
        &:not([disabled]):focus {
          //color: $color-nightrider;
          color: $color-black;
        }
        &.slick-prev {
          background: url("/lg5-common-gp/images/common/icons/carousel-left-over.svg")
            0 0;
          background-size: 22px 40px;
          left: -39px;
          [dir="rtl"] & {
            left: inherit;
            right: -39px;
          }
          @include screen(custom, max, $max-sm) {
            left: 38px;
            background-size: 12px 22px;
            [dir="rtl"] & {
              left: inherit;
              right: 38px;
            }
          }
        }
        &.slick-next {
          background: url("/lg5-common-gp/images/common/icons/carousel-right-over.svg")
            0 0;
          background-size: 22px 40px;
          right: -39px;
          [dir="rtl"] & {
            left: -39px;
            right: inherit;
          }
          @include screen(custom, max, $max-sm) {
            right: 38px;
            background-size: 12px 22px;
            [dir="rtl"] & {
              left: 38px;
              right: inherit;
            }
          }
        }
        &.slick-disabled {
          opacity: 0.3;
        }
        svg {
          display: block;
        }
      }
      .slick-dots {
        text-align: center;
        margin-top: 18px;
        margin-bottom: 0;
        height: 10px;
        li {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 10px;
          height: 10px;
          margin: 0 4px;
          line-height: normal;
          border-radius: 50%;
          border: 1px solid $color-gray;
          a,
          button {
            background: $color-white;
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            border-radius: 50%;
            overflow: hidden;
            color: transparent;
            cursor: pointer;
            &:focus {
              outline: 1px dotted;
              outline: 5px auto -webkit-focus-ring-color;
            }
          }
          &.slick-active {
            border: 1px solid $color-carmine;
            a,
            button {
              background: $color-carmine;
            }
          }
          &:only-child {
            display: none;
          }
        }
      }
    }
  }
}
